import {
  DashboardOutlined,
  UsergroupAddOutlined,
  ScheduleOutlined,
  FontSizeOutlined,
} from "@ant-design/icons";
import { APP_PREFIX_PATH } from "configs/AppConfig";

const dashBoardNavTree = [
  {
    key: "home",
    path: `${APP_PREFIX_PATH}/home`,
    title: "Dashboard",
    icon: DashboardOutlined,
    breadcrumb: false,
    submenu: [],
  },
  {
    key: "pazienti",
    path: `${APP_PREFIX_PATH}/pazienti`,
    title: "Pazienti",
    icon: UsergroupAddOutlined,
    breadcrumb: false,
    submenu: [],
  },
  {
    key: "appuntamenti",
    path: `${APP_PREFIX_PATH}/appuntamenti`,
    title: "Appuntamenti",
    icon: ScheduleOutlined,
    breadcrumb: false,
    submenu: [],
  },
  {
    key: "blog",
    path: `${APP_PREFIX_PATH}/gestisci-blog`,
    title: "Blog",
    icon: FontSizeOutlined,
    breadcrumb: false,
    submenu: [],
  },
  {
    key: "logout",
    path: `${APP_PREFIX_PATH}/logout`,
    title: "Logout",
    breadcrumb: false,
    submenu: [],
  },
];

const navigationConfig = [...dashBoardNavTree];

export default navigationConfig;
