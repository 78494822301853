import fetch from "auth/FetchInterceptor";
import { AUTH_PREFIX_PATH } from "configs/AppConfig";
import { AUTH_TOKEN } from "redux/constants/Auth";

const AuthService = {};

AuthService.login = function (data) {
  return fetch({
    url: "/login.php",
    method: "post",
    headers: {
      "public-request": "true",
    },
    data: data,
  });
};

AuthService.logout = function () {
  localStorage.removeItem(AUTH_TOKEN);
  window.location.replace(`${AUTH_PREFIX_PATH}/login`);
};

export default AuthService;
